import axios from "axios";

let http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        "Content-type": "application/json",
        // "X-LABLOG-HOST": process.env.VUE_APP_CUSTOM_HOST
        },
    })

http.std_error_handler = function (obj, err) {
    let response = true;
    try{
        console.log(err.response.data)
    } catch{
        console.log(err)
    }
    if (err.response === undefined) {
        console.log("Error: ", err);
    } else if (err.response.status === 401) {
        let msg = ''
        try {
            msg = err.response.data.detail;
        } catch {
            msg = 'An error Occurred.';
        }
        try {
            obj(msg);
        } catch {
            obj.$emit('login-expired', msg)
        }
        response = false;
    } else if(err.response.status === 422) {
        try {
            obj.$emit('show-alert', {
                message: err.response.data.detail[0].msg,
                type: 'danger',
            })
        } catch {
            console.log(err);
        }
    } else if(err.response.status === 400) {
        try {
            obj.$emit('show-alert', {
                message: err.response.data.detail,
                type: 'danger',
            })
        } catch {
            console.log(err);
        }
    } else {
        console.log(err);
    }
    return response
};

http.upload_files = function(files, attachPromise, callback) {
    const upload_promises = [];
    files.forEach((file) => {
        if (file !== null && file.size > 0) {
            let formData = new FormData();
            formData.append('file', file);
            upload_promises.push(http.post('resource/',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .catch((err) => {
                    http.std_error_handler(this, err);
                }));
        }
    });
    Promise.all(upload_promises)
        .then((responses) => {
            const attach_promises = [];
            const upload_infos = [];
            responses.forEach((response) => {
                try {
                    attach_promises.push(attachPromise(response.data.id));
                    upload_infos.push(response.data);
                    // eslint-disable-next-line no-empty
                } catch { }
            });
            Promise.all(attach_promises)
                .then(() => {
                    callback(upload_infos);
                })
        });

};

export default http