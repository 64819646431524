import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import("@/components/Home"),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import("@/components/UserLogin"),
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import("@/components/ResetPassword"),
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import("@/components/UserProfile"),
        },
        {
            path: '/draft',
            name: 'draft',
            component: () => import("@/components/EditDraft"),
        },
        {
            path: '/edit/:id',
            name: 'edit',
            component: () => import("@/components/EditDraft"),
        },
        {
            path: '/reply/:id',
            name: 'reply',
            component: () => import("@/components/EditDraft"),
        },
        {
            path: '/template/:id',
            name: 'edit-template',
            component: () => import("@/components/EditDraft"),
        },
        {
            path: '/update-task/:id',
            name: 'update-task',
            component: () => import("@/components/EditDraft"),
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import("@/components/SettingsMain"),
        },
        {
            path: '/tasks',
            name: 'tasks',
            component: () => import("@/components/TaskHome")
        },
        {
            path: '/task/:id',
            name: 'view-task',
            component: () => import("@/components/Task")
        },
        {
            path: '/edit-task',
            name: 'edit-new-task',
            component: () => import("@/components/EditTask")
        },
        {
            path: '/edit-task/:id',
            name: 'edit-task',
            component: () => import("@/components/EditTask")
        },
        {
            path: '/requisitions',
            name: 'requisitions',
            component: () => import("@/components/RequisitionHome")
        },
        {
            path: '/edit-requisition/:id',
            name: 'edit-requisition',
            component: () => import("@/components/EditRequisition")
        },
        {
            path: '/requisition/:id',
            name: 'view-requisition',
            component: () => import("@/components/EditRequisition")
        },
        {
            path: '/submission/shared/:id/:share_key',
            name: 'view-shared-submission',
            component: () => import("@/components/Submission"),
        },
        {
            path: '/submission/:id',
            name: 'view-submission',
            component: () => import("@/components/Submission"),
        },
        {
            path: '/sop',
            name: 'sop-home',
            component: () => import("@/components/SOPHome"),
        },
        {
            path: '/phonebook',
            name: 'phonebook',
            component: () => import("@/components/Phonebook"),
        },
        {
            path: '/materiel',
            name: 'materiel',
            component: () => import("@/components/MaterielHome"),
        },
        {
            path: '/edit-materiel/:id',
            name: 'edit-materiel',
            component: () => import("@/components/EditMateriel"),
        },
        {
            path: '/shipments',
            name: 'shipments',
            component: () => import("@/components/ShipmentHome"),
        },
        {
            path: '/edit-shipment/:id',
            name: 'edit-shipment',
            component: () => import("@/components/EditShipment"),
        },
        {
            path: '*',
            name: 'star',
            component: () => import("@/components/Home"),
        },
    ]
});