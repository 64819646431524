<template>
  <b-container fluid id="app">
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-brand to="/">{{application_name}}</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-show="access_token !== ''">
          <b-nav-item to="/draft">Compose</b-nav-item>
          <b-nav-item to="/tasks">Tasks</b-nav-item>
          <b-nav-item to="/requisitions">Requisitions</b-nav-item>
          <b-nav-item to="/materiel">Materiel</b-nav-item>
          <b-nav-item to="/shipments">Shipments</b-nav-item>
          <b-nav-item v-if="sop_enabled" to="/sop">SOPs</b-nav-item>
          <b-nav-item to="/phonebook">Phonebook</b-nav-item>
          <b-nav-item v-show="current_user.is_admin" to="/settings">Settings</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" v-show="access_token !== ''">

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{current_user.full_name}}</em>
            </template>
            <b-dropdown-item to="/profile">Profile</b-dropdown-item>
            <b-dropdown-item v-on:click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto" v-show="access_token === ''">
          <b-nav-item to="/login">Login/Register</b-nav-item>
        </b-navbar-nav>


      </b-collapse>
    </b-navbar>

    <b-alert v-model="alert_show" :variant=alert_type dismissible>{{alert_data}}</b-alert>
    <keep-alive :include="['Home', 'MaterielHome']">
      <router-view
          v-on:login-complete="loginComplete($event)"
          v-on:login-expired="loginExpired($event)"
          v-on:show-alert="showAlert($event)"
          :loggedIn="access_token !== ''"
          :current_user="current_user"
          :my_tasks="my_tasks"
          v-on:sync-tags="syncTags($event)"
          v-on:sync-users="syncUsers($event)"
          v-on:sync-cost-objects="syncCostObjects($event)"
          v-on:sync-my-tasks="syncMyTasks($event)"
          :tags="tags"
          :users="users"
          :active_users="active_users"
          :cost_objects="cost_objects"
          :key="$route.fullPath"
      ></router-view>
    </keep-alive>
  </b-container>
</template>

<script>
import http from "./http-common"

export default {
  name: 'App',
  title: 'LabLog',
  components: {
  },
  data: function () {
    return {
      application_name: 'LabLog',
      access_token: '',
      current_user: {
        username: '',
        full_name: '',
        is_admin: false,
      },
      tags: [],
      users: [],
      active_users: [],
      cost_objects: [],
      my_tasks: {
        authored: [],
        assigned: []
      },
      alert_show: false,
      alert_type: 'info',
      alert_data: '',
    }
  },
  methods: {
    loginComplete (login_info){
      this.current_user = {
        username: login_info.user_info.username,
        full_name: login_info.user_info.full_name,
        is_admin: login_info.user_info.is_admin,
      }
      localStorage.current_user = JSON.stringify(this.current_user);

      this.access_token = login_info.access_token;
      localStorage.access_token = this.access_token;
      http.defaults.headers.common['Authorization'] = 'Bearer ' + this.access_token;
      this.clearAlert();
      this.$router.push('/');
    },
    loginExpired (msg) {
      this.logout();
      this.showAlert({
        message: msg,
        type: "warning",
      });
    },
    showAlert (event) {
      this.alert_show = true;
      this.alert_data = event.message;
      this.alert_type = event.type;
    },
    clearAlert () {
      this.alert_show = false;
      this.alert_data = '';
    },
    logout (){
      this.access_token = '';
      localStorage.access_token = this.access_token;
      this.$router.push('/login');
      this.showAlert({
        message: "You have logged out",
        type: "info",
      });
    },
    syncTags() {
      http.get('tag/')
          .then((resp) => {
            this.tags = resp.data;
          })
          .catch((err) => {
            http.std_error_handler(this.loginExpired, err)
          });
    },
    syncUsers(callback) {
      http.get('user/')
          .then((resp) => {
            this.users = resp.data;
            this.active_users = this.users.filter((user) => user.is_active);
            if ((callback || null) !== null) {
              callback();
            }
          })
          .catch((err) => {
            http.std_error_handler(this.loginExpired, err)
          });
    },
    syncCostObjects(callback) {
      http.get('requisition/cost-object')
          .then((resp) => {
            this.cost_objects = resp.data;
            if ((callback || null) !== null) {
              callback();
            }
          })
          .catch((err) => {
            http.std_error_handler(this.loginExpired, err)
          });
    },
    syncMyTasks() {
      http.get('task/mine')
          .then((resp) => {
            this.my_tasks = resp.data;
          })
          .catch((err) => {
            http.std_error_handler(this.loginExpired, err)
          });
    },
  },
  computed: {
    sop_enabled() {
      return process.env.VUE_APP_GH_URL !== undefined;
    },
  },
  created () {
    let existing_token = localStorage.getItem('access_token');
    if (existing_token) {
      this.access_token = existing_token;
      http.defaults.headers.common['Authorization'] = 'Bearer ' + existing_token;
      this.current_user = JSON.parse(localStorage.getItem('current_user'));
    } else {
      this.access_token = '';
    }
    let app_name = process.env.VUE_APP_APPLICATION_NAME;
    if (app_name) {
      this.application_name = app_name;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar {
  background-color: #D00000;
}
.navbar-nav > .nav-item > .nav-link {
  font-weight: bolder;
}
.navbar-nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.6);
}
.navbar-nav > .nav-item > .nav-link:focus {
  color: rgba(255, 255, 255, 0.95);
}
</style>